<template>
  <b-form>
    <b-card title="Önem Derecesi Güncelle">
      <b-form-group
        label="Başlık"
        label-for="title"
      >
        <b-form-input
          id="title"
          v-model="formData.title"
          placeholder="Başlık"
        />
      </b-form-group>
    </b-card>
    <b-button
      variant="danger"
      :disabled="submitStatus"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </b-form>
</template>

<script>
import {
  BForm,
  BCard,
  BFormInput,
  BFormGroup,
  BButton,
} from 'bootstrap-vue'

export default {
  name: 'Edit',
  components: {
    BForm,
    BCard,
    BFormInput,
    BFormGroup,
    BButton,
  },
  data() {
    return {
      detailForm: false,
      submitStatus: false,
      formData: {
        title: null,
      },
    }
  },
  computed: {
    levelData() {
      return this.$store.getters['crmLevels/getCrm_level']
    },
    saveStatus() {
      return this.$store.getters['crmLevels/getCrm_levelSaveStatus']
    },
  },
  watch: {
    levelData(val) {
      this.setFormData(val)
    },
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: 'Kayıt işleminiz başarılı!',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: 'Kayıt işleminiz yapılamadı.! Lütfen tekrar deneyiniz.',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    this.getLevel()
  },
  methods: {
    getLevel() {
      this.$store.dispatch('crmLevels/crm_levelView', this.$route.params.id)
    },
    setFormData(data) {
      this.formData = data
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      this.submitStatus = true
      this.$store.dispatch('crmLevels/crm_levelSave', this.formData)
    },
  },
}
</script>
